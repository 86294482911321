<template>
  <div id="zxView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div>指血打卡</div>
    </div>
    <div class="mainBody">
      <van-field label="指血数值(mmol/L)" type="number" v-model='zxNum' placeholder="请输入" input-align="right" style="padding-right: 3rem;" />
      <van-cell title="指血时间" is-link :value="selectTime" @click="openSelectTime" />
      <van-cell style="padding-left: 17px;padding-top: 15px;">指血场景</van-cell>
      <!-- <el-row style="padding-left: 15px;padding-top: 5px;font-size: xx-small;font-weight: lighter;">一般指任意时间获得的血糖</el-row> -->
    </div>
    <div>
      <el-row><el-col v-for="(item,index) in zxscene1" :key="index" :span="8" style="margin-bottom: 10px;">
          <el-button round @click="selectZx1(item)">{{ item.id}}</el-button>
        </el-col></el-row>
      <el-row><el-col v-for="(item,index) in zxscene2" :key="index" :span="8" style="margin-bottom: 10px;">
          <el-button round @click="selectZx2(item)">{{ item.id}}</el-button>
        </el-col></el-row>
      <el-row><el-col v-for="(item,index) in zxscene3" :key="index" :span="8">
          <el-button round @click="selectZx3(item)">{{ item.id}}</el-button>
        </el-col></el-row>
    </div>
    <el-row style="margin-top: 2rem;">
      <el-col :offset="2" :span="20">
        <el-button @click="addzx" style="width: 100%;color:#fff; background: #00dac4; border-radius: 1rem;margin-bottom: 6rem;height: 3rem;">
          完成
        </el-button>
      </el-col>
    </el-row>
    <van-popup v-model="showTimeSelect" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="currentDate" type="datetime" title="选择完整时间" :min-date="minDate" :max-date="maxDate" @confirm="saveTime" :formatter="formatter" />
    </van-popup>
  </div>
</template>
<script>
import { addEvent } from '@/api/userServices'
export default {
  name: 'zxView',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      zxNum: '',
      zxscene1: [{ id: '夜间' }, { id: '空腹' }, { id: '早餐后' }],
      zxscene2: [{ id: '午餐前' }, { id: '午餐后' }, { id: '晚餐前' }],
      zxscene3: [{ id: '晚餐后' }, { id: '睡前' }, { id: '随机' }],
      selectedScene: '',
      showTimeSelect: false,
      selectTime: this.getFormatDate(new Date()),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date()
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    selectZx1(item) {
      this.selectedScene = item.id
      console.log(item.id)
    },
    selectZx2(item) {
      this.selectedScene = item.id
      console.log(item.id)
    },
    selectZx3(item) {
      this.selectedScene = item.id
      console.log(item.id)
    },
    goBack() {
      this.$router.push('/login')
    },
    setBloodSugerTojs() {
      console.log('点击')
    },
    openSelectTime() {
      this.showTimeSelect = true
    },
    saveTime(value) {
      this.selectTime = this.getFormatDate(value)
      this.showTimeSelect = false
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    getFormatDate(time) {
      const date = new Date(time)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      hour = hour < 10 ? '0' + hour : hour
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
      return currentDate
    },
    addzx() {
      const params = {
        type: 'zx',
        createTime: this.selectTime,
        scene: '--',
        way: this.zxNum,
        slopeOrdinal: localStorage.getItem('slopeOrdinal') ? localStorage.getItem('slopeOrdinal') : '3',
        value: localStorage.getItem('bloodSuger') ? localStorage.getItem('bloodSuger') : '5',
        eventValue: this.selectedScene
      }
      // for (let index = 0; index < this.drugUsed.length; index++) {
      //     const element = this.drugUsed[index]
      //     params.way = element.name
      //     params.eventValue = element.value
      addEvent(params).then(res => {
        if (res.data.code === '200') {
          this.$message.success('添加成功!')
          this.$router.push('/bloodSugar')
        } else {
          this.$message.error('添加失败，请输入完整信息！')
        }
      })
      // }
    }
  }
}
</script>
<style scoped>
#zxView {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 100;
}

.topHeader {
  height: 4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
}

.mainBody {
  text-align: left;
  /* font-weight: bolder; */
  font-size: 14px;
  color: black;
}
.el-icon-arrow-left {
  position: absolute;
  top: 0;
  left: 24px;
  line-height: 4rem;
  font-size: 2rem;
}
</style>
