import { render, staticRenderFns } from "./myPatients.vue?vue&type=template&id=8e182c98&scoped=true"
import script from "./myPatients.vue?vue&type=script&lang=js"
export * from "./myPatients.vue?vue&type=script&lang=js"
import style0 from "./myPatients.vue?vue&type=style&index=0&id=8e182c98&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e182c98",
  null
  
)

export default component.exports