import { render, staticRenderFns } from "./alarmCFG0.vue?vue&type=template&id=45a9dd0f&scoped=true"
import script from "./alarmCFG0.vue?vue&type=script&lang=js"
export * from "./alarmCFG0.vue?vue&type=script&lang=js"
import style0 from "./alarmCFG0.vue?vue&type=style&index=0&id=45a9dd0f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a9dd0f",
  null
  
)

export default component.exports