<template>
  <div id="ydsView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div>胰岛素打卡</div>
    </div>
    <div class="mainBody">
      <van-cell title="注射时间" is-link :value="selectTime" @click="openSelectTime" />
      <van-field label="注射时段" style="padding-right: 3rem;" input-align="right" placeholder="请输入" v-model="InjectionTime" />
      <van-cell-group style="margin-bottom: 0.5rem;">
        <van-field style="border-top:10px solid #e4e7ed;padding-right: 3rem;" label="胰岛素名称" input-align="right" placeholder="请输入胰岛素名称" v-model="inject.ydsName" />
        <van-field v-model="inject.doze" type="number" label="注射剂量(单位/U)" style="padding-right: 3rem;" input-align="right" placeholder="请输入注射剂量" /></van-cell-group>
    </div>
    <!-- <van-cell-group style="margin-bottom: 0.5rem;border-top:10px solid #e4e7ed">
                <el-row style="margin-top: 20px;font-size: smaller;color: black;margin-left: 17px;margin-bottom: 30px;">
                    <el-col :span="10">历史胰岛素打卡剂量</el-col><el-col :span="7">最近{{ dozenum }}条</el-col></el-row>
        </van-cell-group>
        <div style="width: 100%;height: 100px;margin: auto;">暂无历史胰岛素打卡记录</div> -->
    <el-col :offset="2" :span="20">
      <el-button @click="addyds" style="width: 100%;color:#fff; background: #00dac4; border-radius: 1rem;margin-bottom: 6rem;height: 3rem;">
        完成
      </el-button></el-col>
    <van-popup v-model="showTimeSelect" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="currentDate" type="datetime" title="选择完整时间" :min-date="minDate" :max-date="maxDate" @confirm="saveTime" :formatter="formatter" />
    </van-popup>
  </div>
</template>
<script>
import { addEvent } from '../../../api/userServices'
export default {
  name: 'ydsView',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      InjectionTime: '',
      inject: {
        doze: '',
        ydsName: ''
      },
      // dozenum: '3',
      showTimeSelect: false,
      selectTime: this.getFormatDate(new Date()),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date()
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.push('/login')
    },
    openSelectTime() {
      this.showTimeSelect = true
    },
    saveTime(value) {
      this.selectTime = this.getFormatDate(value)
      this.showTimeSelect = false
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    getFormatDate(time) {
      const date = new Date(time)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      hour = hour < 10 ? '0' + hour : hour
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
      return currentDate
    },
    addyds() {
      if (
        this.selectTime === null ||
        this.InjectionTime === null ||
        this.inject.doze === null ||
        this.inject.ydsName === null ||
        this.selectTime === '' ||
        this.InjectionTime === '' ||
        this.inject.doze === '' ||
        this.inject.ydsName === ''
      ) {
        this.$message.error('添加失败，请输入完整信息！')
      } else {
        const params = {
          type: 'yds',
          createTime: this.selectTime,
          scene: this.InjectionTime,
          way: this.inject.doze,
          slopeOrdinal: localStorage.getItem('slopeOrdinal') ? localStorage.getItem('slopeOrdinal') : '3',
          value: localStorage.getItem('bloodSuger') ? localStorage.getItem('bloodSuger') : '5',
          eventValue: this.inject.ydsName
        }
        // for (let index = 0; index < this.inject.length; index++) {
        //     const element = this.inject[index]
        //     params.way = element.doze
        //     params.eventValue = element.dozenum
        addEvent(params).then(res => {
          if (res.data.code === '200') {
            this.$message.success('添加成功!')
            this.$router.push('/bloodSugar')
          } else {
            this.$message.error('添加失败，请输入完整信息！')
          }
        })
        // }
      }
    }
  }
}
</script>
<style scoped>
#ydsView {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 100;
}

.topHeader {
  height: 4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
}

.mainBody {
  text-align: left;
  /* font-weight: bolder; */
  font-size: 14px;
  color: black;
}
.el-icon-arrow-left {
  position: absolute;
  top: 0;
  left: 24px;
  line-height: 4rem;
  font-size: 2rem;
}
</style>
