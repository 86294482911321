// 组织架构树
import api from './services.js'
import { baseUrl } from './host.js'
const env = baseUrl.env;
export const getAll = (param) => api({
  url: param.url,
  method: param.method,
  params: param.params
})
// eslint-disable-next-line no-unused-vars
const host = baseUrl[env].host
// eslint-disable-next-line no-unused-vars
const port = baseUrl[env].port

// 查询所有用户
export const findAllUser = (params) => api({
  url: host + port.user + 'auth/getUser/page',
  method: 'get',
  params: params
})
// 查询所有组织
export const findAllStruct = (params) => api({
  url: host + port.user + 'auth/struct/select',
  method: 'get',
  params: params
})
