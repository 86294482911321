<template>
  <div id="alarmCFG">
    <div
      style="
        width: 100%;
        padding-left: 3%;
        height: 4rem;
        background: #fff;
        position: fixed;
        top: 0;
        z-index: 9;
        border-bottom: 1px solid #ddd;
      "
    >
      <div
        @click="goBackBlood"
        style="
          height: 4rem;
          line-height: 4rem;
          position: absolute;
          left: 0;
          color: #8dd4ff;
          padding-left: 3%;
          font-size: 1.6rem;
        "
      >
        <i class="el-icon-arrow-left"></i>返回
      </div>
      <div
        style="
          line-height: 4rem;
          font-size: 1.6rem;
          font-weight: bold;
          text-align: center;
        "
      >
        警报
      </div>
    </div>
    <div
      style="
        margin-left: 6%;
        color: #8f8f8f;
        height: 4rem;
        line-height: 4rem;
        margin-top: 4rem;
      "
    >
      警报设置
    </div>
    <div
      style="
        border-radius: 12px;
        overflow: hidden;
        width: 94%;
        margin-left: 3%;
        font-weight: bold;
      "
    >
      <van-cell-group>
        <van-cell
          v-for="(item, i) in alarmCFGList"
          :key="'cfg' + i"
          :title="item.title"
          v-show="item.show"
        >
          <template #right-icon>
            <el-switch
              v-model="item.flag"
              @change="changeConfig(item.name)"
              active-color="#13ce66"
              inactive-color="#8f8f8f"
            >
            </el-switch>
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div
      style="margin-left: 6%; color: #8f8f8f; height: 4rem; line-height: 4rem"
    >
      血糖目标范围
    </div>
    <div
      style="
        border-radius: 12px;
        overflow: hidden;
        width: 94%;
        margin-left: 3%;
        font-weight: bold;
      "
    >
      <van-field
        v-model="targetHigh"
        @blur="maxSugarChage(targetHigh)"
        type="number"
        label="血糖目标上限"
        input-align="right"
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
      <van-field
        v-model="targetLow"
        @blur="minSugarChage(targetLow)"
        type="number"
        label="血糖目标下限"
        input-align="right"
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
      <van-field
        v-model="bgHigh"
        @blur="highChage(bgHigh)"
        type="number"
        label="高血糖值"
        input-align="right"
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
      <van-field
        v-model="bgLow"
        @blur="lowChage(bgLow)"
        type="number"
        label="低血糖值"
        input-align="right"
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
      <van-field
        v-model="bgVeryLow"
        @blur="verylowChage(bgVeryLow)"
        type="number"
        label="极低血糖值"
        input-align="right"
      >
        <template #button>
          <span>mmol/L</span>
        </template>
      </van-field>
    </div>
    <div
      style="margin-left: 6%; color: #8f8f8f; height: 4rem; line-height: 4rem"
    >
      语音播报
    </div>
    <div
      style="
        border-radius: 12px;
        overflow: hidden;
        width: 94%;
        margin-left: 3%;
        font-weight: bold;
      "
    >
      <van-cell-group>
        <van-cell
          v-for="(item, i) in voiceCFGList"
          :key="'cfg' + i"
          :title="item.title"
        >
          <template #right-icon>
            <el-switch
              v-if="!item.type"
              v-model="item.flag"
              @change="changeConfig(item.name)"
              active-color="#13ce66"
              inactive-color="#8f8f8f"
            >
            </el-switch>
            <span v-if="item.type == 'language'">{{ item.language }}</span>
            <span v-if="item.type == 'timeInterval'"
              >{{ item.timeInterval }}分钟</span
            >
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div style="width: 100%; height: 8rem"></div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { updatebutoken } from "../../../api/userServices";
import Vue from "vue";
export default {
  name: "alarmCFG",
  data() {
    return {
      targetHigh: 10,
      targetLow: 3.9,
      bgHigh: 12.6,
      bgLow: 5.0,
      bgVeryLow: 3.5,
      alarmCFGList: [
        {
          name: "alarmMain",
          title: "警报总开关",
          flag: true,
          show: false,
        },
        {
          name: "emergentLow",
          title: "紧急低血糖",
          flag: true,
          show: true,
        },
        {
          name: "low",
          title: "低血糖",
          flag: true,
          show: true,
        },
        {
          name: "height",
          title: "高血糖",
          flag: true,
          show: true,
        },
        {
          name: "emergentHigh",
          title: "紧急高血糖",
          flag: true,
          show: true,
        },
        {
          name: "signalLost",
          title: "信号丢失",
          flag: true,
          show: true,
        },
        {
          name: "calibrationTip",
          title: "校准提醒",
          flag: true,
          show: true,
        },
      ],
      voiceCFGList: [
        {
          name: "voicePlayback",
          title: "语音播报血糖值",
          flag: true,
        },
        {
          name: "language",
          title: "语言",
          type: "language",
          language: "中文",
        },
        {
          name: "voiceTrend",
          title: "语音播报趋势值",
          flag: true,
        },
        {
          name: "voiceChangeRate",
          title: "语音播报变化率",
          flag: true,
        },
        {
          name: "timeInterval",
          title: "时间间隔",
          type: "timeInterval",
          timeInterval: "5",
        },
      ],
      configData: {
        alarmMain: "",
        emergentLow: "",
        low: "",
        height: "",
        emergentHigh: "",
        signalLost: "",
        calibrationTip: "",
        voicePlayback: "",
        language: "",
        voiceTrend: "",
        voiceChangeRate: "",
        timeInterval: "",
      },
    };
  },
  mounted() {
    const config = JSON.parse(localStorage.getItem("appuser")).config;
    const cobj = JSON.parse(config);
    const appuser = JSON.parse(localStorage.getItem("appuser"));
    this.targetHigh = appuser.targetHigh
      ? appuser.targetHigh
      : this.targetHigh;
    this.targetLow = appuser.targetLow
      ? appuser.targetLow
      : this.targetLow;
    this.bgHigh = appuser.bgHigh
      ? appuser.bgHigh
      : this.bgHigh;
    this.bgLow = appuser.bgLow
      ? appuser.bgLow
      : this.bgLow;
    this.bgVeryLow = appuser.bgVeryLow
      ? appuser.bgVeryLow
      : this.bgVeryLow;
    if (config && JSON.parse(config)) {
      for (let index = 0; index < this.alarmCFGList.length; index++) {
        const element = this.alarmCFGList[index];
        if (cobj[element.name] === "1") {
          this.alarmCFGList[index].flag = true;
        } else if (cobj[element.name] === "0") {
          this.alarmCFGList[index].flag = false;
        }
      }
      for (let index = 0; index < this.voiceCFGList.length; index++) {
        const element = this.voiceCFGList[index];
        if (cobj.type === "language") {
          this.voiceCFGList[index].language = cobj[element.name];
        } else if (cobj.type === "timeInterval") {
          this.voiceCFGList[index].timeInterval = cobj[element.name];
        } else {
          if (cobj[element.name] === "1") {
            this.voiceCFGList[index].flag = true;
          } else if (cobj[element.name] === "0") {
            this.voiceCFGList[index].flag = false;
          }
        }
      }
    }
  },
  methods: {
    maxSugarChage(e) {
      if (Number(e) < Number(this.targetLow)) {
        Toast("血糖上限不能小于血糖下限！");
        this.targetHigh = 10;
      } else {
        this.targetHigh = e;
        this.changeConfig();
      }
      Vue.prototype.$targetHigh = this.targetHigh;
    },
    minSugarChage(e) {
      if (Number(e) > Number(this.targetHigh)) {
        Toast("血糖下限不能大于血糖上限！");
        this.targetLow = 3.9;
      } else {
        this.targetLow = e;
        this.changeConfig();
      }
      Vue.prototype.$targetLow = this.targetLow;
    },
    highChage(e) {
      if (
        Number(e) < Number(this.bgLow) ||
        Number(e) < Number(this.bgVeryLow)
      ) {
        Toast("高血糖值不能小于低血糖值和极低血糖值！");
        this.bgHigh = 12.6;
      } else {
        this.bgHigh = e;
        this.changeConfig();
      }
    },
    lowChage(e) {
      if (
        Number(e) > Number(this.bgHigh) ||
        Number(e) < Number(this.bgVeryLow)
      ) {
        Toast("低血糖值不能大于高血糖值和小于极低血糖值！");
        this.bgLow = 5.0;
      } else {
        this.bgLow = e;
        this.changeConfig();
      }
    },
    verylowChage(e) {
      if (
        Number(e) > Number(this.bgLow) ||
        Number(e) > Number(this.bgHigh)
      ) {
        Toast("极低血糖值不能大于高血糖值和低血糖值！");
        this.bgVeryLow = 3.5;
      } else {
        this.bgVeryLow = e;
        this.changeConfig();
      }
    },
    goBackBlood() {
      this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
    },
    changeConfig() {
      for (let index = 0; index < this.alarmCFGList.length; index++) {
        const element = this.alarmCFGList[index];
        if (element.flag) {
          this.configData[element.name] = "1";
        } else {
          this.configData[element.name] = "0";
        }
      }
      for (let index = 0; index < this.voiceCFGList.length; index++) {
        const element = this.voiceCFGList[index];
        if (element.type === "language") {
          this.configData.language = element.language;
        } else if (element.type === "timeInterval") {
          this.configData.timeInterval = element.timeInterval;
        } else {
          if (element.flag) {
            this.configData[element.name] = "1";
          } else {
            this.configData[element.name] = "0";
          }
        }
      }
      const params = {
        config: JSON.stringify(this.configData),
        targetHigh : this.targetHigh,
          targetLow : this.targetLow,
          bgHigh : this.bgHigh,
          bgLow : this.bgLow,
          bgVeryLow : this.bgVeryLow
      }
      updatebutoken(params).then((res) => {
        if (res.data.code == 200) {
          const appuser = JSON.parse(localStorage.getItem("appuser"));
          appuser.config = JSON.stringify(this.configData);
          appuser.targetHigh = this.targetHigh
          appuser.targetLow = this.targetLow
          appuser.bgHigh = this.bgHigh
          appuser.bgLow = this.bgLow
          appuser.bgVeryLow = this.bgVeryLow
          localStorage.setItem("appuser", JSON.stringify(appuser));
          Toast(res.data.msg);
        } else {
          return Toast(res.data.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
#alarmCFG {
  background: #f8f8f8;
  text-align: left;
}
::v-deep .van-field__control--right {
  padding-right: 10px;
}
::v-deep .van-field__label {
  color: #323233;
}
</style>
