<template>
  <div class="common-box" :class="isFullScreen ? 'shuiping' : 'chuizhi'">
    <div ref="imgDom" class="imgDom">
      <!-- 标题 -->
      <div class="common-title">近{{ time }}小时血糖曲线</div>
      <!-- 最高最低值底图 -->
      <!-- <div class="chartCan">
        <span class="bottom-num">3.9</span>
        <span class="top-num">10.0</span>
      </div> -->
      <!-- 单位 -->
      <span class="unit">mmol/L</span>
      <!-- 打开 关闭全屏 -->
      <div class="handleShow" style="right: 3.5rem" @click="refresh()">
        <img src="../../../static/pic/refresh.png" alt="" />
      </div>
      <div class="handleShow" @click="isFullScreen ? outFull() : inFull()">
        <img
          :src="
            isFullScreen
              ? require('../../../static/pic/nofull.png')
              : require('../../../static/pic/full.png')
          "
        />
      </div>
      <!-- 事件标记图 -->
      <div class="marker" id="marker"></div>
      <!-- 散点图 -->
      <div
        v-show="chartData"
        id="container"
        ref="container"
        style="width: 100%; height: 18rem"
      ></div>
      <!-- 临时基础率梯形图 -->
      <div id="temp" ref="temp"></div>
      <!-- 时间切换 -->
      <div style="padding: 1rem 0">
        <van-radio-group
          v-model="time"
          @change="getDateArray(time)"
          direction="horizontal"
        >
          <van-radio :name="3"
            >3H
            <template #icon="props">
              <img
                v-if="props.checked"
                class="img-icon"
                src="../../../static/pic/timesed.png"
              />
              <img
                v-else
                class="img-icon"
                src="../../../static/pic/timenosed.png"
              />
            </template>
          </van-radio>
          <van-radio :name="6"
            >6H<template #icon="props">
              <img
                v-if="props.checked"
                class="img-icon"
                src="../../../static/pic/timesed.png"
              />
              <img
                v-else
                class="img-icon"
                src="../../../static/pic/timenosed.png"
              /> </template
          ></van-radio>
          <van-radio :name="12"
            >12H<template #icon="props">
              <img
                v-if="props.checked"
                class="img-icon"
                src="../../../static/pic/timesed.png"
              />
              <img
                v-else
                class="img-icon"
                src="../../../static/pic/timenosed.png"
              /> </template
          ></van-radio>
          <van-radio :name="24"
            >24H<template #icon="props">
              <img
                v-if="props.checked"
                class="img-icon"
                src="../../../static/pic/timesed.png"
              />
              <img
                v-else
                class="img-icon"
                src="../../../static/pic/timenosed.png"
              /> </template
          ></van-radio>
        </van-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
import { Line, G2, Scatter } from "@antv/g2plot";
import { getSugerbyId, getEvent } from "@/api/userServices";
import { loopTreatments, getLoopHandleList } from "../../../api/relationship";
const G = G2.getEngine("canvas");
export default {
  props: {
    userId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isFullScreen: false,
      minTime: "",
      tickInterval: "",
      scatterPlot: null,
      scatterPlot2: null,
      tempPlot: null,

      chartData: [],
      eventArr: [], // 糖仁云添加事件列表
      tempBasal: [], // 临时基础率

      time: 3, // 时间段选择
      timer: null, // 5分钟一次的定时器
      timerNum: 0,
      startEventTime: "",

      CorrectionBolus: [], // 胰岛素
      SuspendPump: [], // 泵
      CarbCorrection: [], // 碳水
      TemporaryOverride: [], // 覆盖方波
      loopType: "",
      maxRate: "",
    };
  },
  created() {
    setTimeout(() => {
      this.getChart();
      this.getTempChart();
    }, 300);
    this.getDateArray(this.time);
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timerNum++;
      this.getDateArray(this.time);
    }, 2 * 60 * 1000);
    this.$once("hook:beforeDestroy", () => {
      // 页面关闭
      clearInterval(this.timer); // 停止
    });
  },
  methods: {
    // 血糖事件集合数组按照事件排序
    sortTime(obj) {
      obj.sort((a, b) => {
        let t1 = new Date(Date.parse(a.create_time.replace(/-/g, "/")));
        let t2 = new Date(Date.parse(b.create_time.replace(/-/g, "/")));
        return t1.getTime() - t2.getTime();
      });
      return obj;
    },
    refresh() {
      this.scatterPlot2.destroy();
      this.getDateArray(this.time);
      this.$emit("refresh");
    },
    // 获取临时基础率
    getTreat() {
      this.tempBasal = [];
      this.CarbCorrection = [];
      this.CorrectionBolus = [];
      this.SuspendPump = [];
      this.TemporaryOverride = [];
      const uid = JSON.parse(localStorage.getItem("appuser")).id;
      const routePath = this.$route.path;
      const params = {
        uid: routePath == "/followView" ? this.userId : uid, // 判断是否是从关注页看的图表
        start: this.$moment(this.minTime).format("YYYY-MM-DD HH:mm:ss"),
        end: this.$moment(this.maxTime).format("YYYY-MM-DD HH:mm:ss"),
      };
      let lastNow = this.$moment().subtract(24, "hours").valueOf();
      loopTreatments(params).then(({ data }) => {
        // 临时基础率是用配置文件里的基础率，当有临时基础率时以临时基础率为准
        const end = this.$moment().format("YYYY-MM-DD");
        const start = this.$moment().subtract(24, "hours").format("YYYY-MM-DD");
        let today = [];
        let yestoday = [];
        data.profile.store.Default.basal.forEach((item) => {
          yestoday.push({
            create_time: start + " " + item.time + ":00",
            rate: item.value,
          });
          today.push({
            create_time: end + " " + item.time + ":00",
            rate: item.value,
          });
        });
        try {
          yestoday.forEach((item, index) => {
            if (
              index == yestoday.length - 1 &&
              this.$moment(item.create_time).valueOf() < lastNow
            ) {
              yestoday = [
                {
                  create_time: params.start,
                  rate: item.rate,
                },
              ];
            } else if (
              index != 0 &&
              this.$moment(item.create_time).valueOf() > lastNow &&
              this.$moment(yestoday[index - 1].create_time).valueOf() < lastNow
            ) {
              yestoday[index - 1].create_time = params.start;
              yestoday = yestoday.slice(index - 1, today.length);
              today = today.slice(0, index);
            }
          });
        } catch (e) {
          console.log(e);
        }
        yestoday.forEach((item) => {
          this.tempBasal.push(item);
        });
        today.forEach((item) => {
          this.tempBasal.push(item);
        });
        // 把所有治疗数据分组
        data.treatments.forEach((item) => {
          // 临时基础率分组
          if (item.eventType === "Temp Basal") {
            item.rate = item.rate * 1;
            this.tempBasal.push(item);
          }
          // 胰岛素 分组
          if (item.eventType === "Correction Bolus") {
            this.CorrectionBolus.push(item);
            // this.eventArr.push(item); // 添加ns事件---胰岛素
          }
          // 泵 分组
          if (item.eventType === "Suspend Pump") {
            this.SuspendPump.push(item);
          }
          // 碳水 分组
          if (item.eventType === "Carb Correction") {
            this.CarbCorrection.push(item);
            // this.eventArr.push(item); // 添加ns事件---碳水
          }
          // 覆盖方波 分组
          if (item.eventType === "Temporary Override") {
            this.TemporaryOverride.push(item);
            // this.eventArr.push(item); // 添加ns事件---覆盖方波
          }
        });
        this.sortTime(this.tempBasal);
        // 获取基础率里面的最大值
        let rateArr = [];
        this.tempBasal.forEach((item) => {
          rateArr.push(item.rate);
        });
        this.maxRate = Math.max(...rateArr);
      });
    },

    // 获取所有事件（糖仁云 + 远程 + ns ）
    getAllEvent() {
      this.eventArr = [];
      const routePath = this.$route.path;
      const appuserId = JSON.parse(localStorage.getItem("appuser")).id;

      // 获取糖仁云事件
      const params = {
        id: routePath == "/followView" ? this.userId : appuserId,
        start: this.$moment(this.startEventTime).valueOf(),
        end: this.$moment().add(1, "hours").valueOf(),
      };
      getEvent(params).then(({ data }) => {
        data.data.forEach((item) => {
          item.v = -2;
          this.eventArr.push(item);
        });
      });

      // 获取远程操作事件
      // const id = routePath == "/followView" ? this.userId : appuserId;
      // getLoopHandleList(id).then(({ data }) => {
      //   data.data.forEach((item) => {
      //     if (item.status == 1) {
      //       item.v = 0;
      //       this.eventArr.push(item);
      //     }
      //   });

      // 获取 ns 事件
      const uid = JSON.parse(localStorage.getItem("appuser")).id;
      const nsParams = {
        uid: routePath == "/followView" ? this.userId : uid, // 判断是否是从关注页看的图表
        start: this.$moment(this.minTime).format("YYYY-MM-DD HH:mm:ss"),
        end: this.$moment(this.maxTime).format("YYYY-MM-DD HH:mm:ss"),
      };
      loopTreatments(nsParams).then(({ data }) => {
        data.treatments.forEach((item) => {
          if (item.eventType === "Temp Basal") {
          }
          // 胰岛素
          else if (item.eventType === "Correction Bolus") {
            item.createTime = item.create_time;
            item.v = 0;
            this.eventArr.push(item); // 添加ns事件---胰岛素
          }
          // 碳水分组
          else if (item.eventType === "Carb Correction") {
            item.createTime = item.create_time;
            item.v = -1;
            this.eventArr.push(item); // 添加ns事件---碳水
          }
          // 覆盖方波分组
          else if (item.eventType === "Temporary Override") {
            item.createTime = item.create_time;
            item.v = -2;
            this.eventArr.push(item); // 添加ns事件---覆盖方波
          }
          // 泵分组
          else if (item.eventType === "Suspend Pump") {
            item.createTime = item.create_time;
            item.v = -2;
            this.eventArr.push(item);
          }
          // 其他
          else {
            item.createTime = item.create_time;
            item.v = -2;
            this.eventArr.push(item);
          }
        });
        // 离得很近的事件往上提
        // for (let i = 0; i < this.eventArr.length; i++) {
        //   for (let j = i + 1; j < this.eventArr.length - 1; j++) {
        //     let iTime = this.$moment(this.eventArr[i].createTime).valueOf();
        //     let jTime = this.$moment(this.eventArr[j].createTime).valueOf();
        //     let time = Math.abs(iTime - jTime);
        //     if (time < 30 * 60 * 1000) {
        //       if (iTime < jTime) {
        //         this.eventArr[j].v = 1;
        //       } else {
        //         this.eventArr[i].v = 1;
        //       }
        //     }
        //   }
        // }
        this.getChart2(); // 在这里调用chart2 是为了保证事件都获取到之后在渲染事件图标
      });
      // });
    },

    getChart() {
      const data = this.chartData;
      this.scatterPlot = new Line("container", {
        appendPadding: [30, 15, 10, 5],
        data,
        xField: "create_time",
        yField: "v",
        supportCSSTransform: true,
        legend: false,
        seriesField: this.time == 3 || this.time == 6 ? "tooltip_time" : "",
        color: this.time == 3 || this.time == 6 ? ["#5b8ff9"] : "",
        size: this.time == 3 || this.time == 6 ? 5 : "",
        shape: this.time == 3 || this.time == 6 ? "circle" : "",
        // 内容框
        tooltip: {
          showTitle: false,
          customContent: (title, data) => {
            if (title !== null) {
              return `<div style="padding:4px;">
                      <div style="font-size:16px;font-weight: 700;margin-bottom:4px;"> ${data[0].data.v}</div>
                      <div> ${data[0].data.tooltip_time}</div>
               </div>`;
            }
          },
        },
        point:
          this.time == 3 || this.time == 6
            ? {
                size: 2,
              }
            : "",
        lineStyle:
          this.time == 3 || this.time == 6
            ? {
                stroke: "transparent",
              }
            : "",

        // scale: {
        //   create_time: {
        //     showLast: true,
        //     // type: 'date',
        //     tickCount: 7, // 总共24个刻度，即每小时2个刻度
        //     mask: 'HH:mm', // 显示格式为小时:分钟
        //     range: [0, 1] // 刻度范围为0到1
        //   }
        // },
        // 口服药kfy
        // https://i.postimg.cc/nL6nCZ4R/kfy.png
        // https://i.postimg.cc/3J3mzfKy/image.png
        // 碳水ts
        // https://i.postimg.cc/yNTwzHf3/ts.png
        // https://i.postimg.cc/sxTXkccS/01.png
        // 胰岛素yds
        // https://i.postimg.cc/fTBCZbZN/yds.png
        // https://i.postimg.cc/9XS7cMF3/1.jpg
        // 运动yd
        // https://i.postimg.cc/SNv9DYkJ/yd.png
        // https://i.postimg.cc/rwxTWjrJ/image.png
        // 指尖血zs
        // https://i.postimg.cc/bJmrr4vs/zx.png
        // https://i.postimg.cc/0yRR8SBJ/wlj-xcx-sl-ico-liuliang01.png
        label: {
          offsetY: 0,
          content: (item) => {
            if (item.type == "kfy") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/3J3mzfKy/image.png",
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 6,
                  y: 2,
                  text: item.type,
                  textAlign: "center",
                  textBaseline: "top",
                  fill: "#000",
                },
              });
              return group;
            } else if (item.type == "ts") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/sxTXkccS/01.png",
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 6,
                  y: 2,
                  text: item.type,
                  textAlign: "center",
                  textBaseline: "top",
                  fill: "#000",
                },
              });
              return group;
            } else if (item.type == "yds") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/9XS7cMF3/1.jpg",
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 6,
                  y: 2,
                  text: item.type,
                  textAlign: "center",
                  textBaseline: "top",
                  fill: "#000",
                },
              });
              return group;
            } else if (item.type == "yd") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/rwxTWjrJ/image.png",
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 6,
                  y: 2,
                  text: item.type,
                  textAlign: "center",
                  textBaseline: "top",
                  fill: "#000",
                },
              });
              return group;
            } else if (item.type == "zs") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/0yRR8SBJ/wlj-xcx-sl-ico-liuliang01.png",
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 6,
                  y: 2,
                  text: item.type,
                  textAlign: "center",
                  textBaseline: "top",
                  fill: "#000",
                },
              });
              return group;
            } else {
              return "";
            }
          },
          labelLine: true,
        },
        xAxis: {
          type: "time",
          max: this.maxTime,
          min: this.minTime,
          tickInterval: this.tickInterval,
          mask: "HH:mm", // 显示格式为小时:分钟
          // range: [0, 1], // 刻度范围为0到1
          tickMethod: (scale) => {
            const { min, max, tickCount } = scale;
            // const avg = (max - min) / tickCount;
            const ticks = [];
            for (let i = min; i <= max; i += this.tickInterval) {
              ticks.push(i);
            }
            return ticks;
          },
        },
        yAxis: {
          min: 0,
          max: 20,
        },
        annotations: [
          {
            type: "region",
            start: [
              "min",
              this.$route.query.targetLow
                ? this.$route.query.targetLow
                : this.$targetLow,
            ],
            end: [
              "max",
              this.$route.query.targetHigh
                ? this.$route.query.targetHigh
                : this.$targetHigh,
            ],
            style: {
              stroke: "#edf0f2",
              lineDash: [2, 2],
            },
          },
          {
            type: "regionFilter",
            start: [
              "min",
              this.$route.query.targetHigh
                ? this.$route.query.targetHigh
                : this.$targetHigh,
            ],
            end: ["max", 20],
            color: "#F4664A",
          },
          {
            type: "text",
            position: [
              "min",
              this.$route.query.targetHigh
                ? this.$route.query.targetHigh
                : this.$targetHigh,
            ],
            content: this.$route.query.targetHigh
              ? this.$route.query.targetHigh
              : this.$targetHigh,
            style: {
              fill: "#F4664A",
            },
          },
          {
            type: "regionFilter",
            start: ["min", 0],
            end: [
              "max",
              this.$route.query.targetLow
                ? this.$route.query.targetLow
                : this.$targetLow,
            ],
            color: "#ffaa29",
          },
          {
            type: "regionFilter",
            start: [
              "min",
              this.$route.query.targetLow
                ? this.$route.query.targetLow
                : this.$targetLow,
            ],
            end: [
              "max",
              this.$route.query.targetHigh
                ? this.$route.query.targetHigh
                : this.$targetHigh,
            ],
            color: "#6395fa",
            stroke: '#6395fa'
          },
          {
            type: "text",
            position: [
              "min",
              this.$route.query.targetLow
                ? this.$route.query.targetLow
                : this.$targetLow,
            ],
            content: this.$route.query.targetLow
              ? this.$route.query.targetLow
              : this.$targetLow,
            style: {
              fill: "#ffaa29",
            },
          },
        ],
      });
      this.scatterPlot.render();
    },

    getChart2() {
      const data = this.eventArr;
      this.scatterPlot2 = new Scatter("marker", {
        appendPadding: [13, 15, 0, 13],
        data,
        xField: "createTime",
        yField: "v",
        size: 5,
        supportCSSTransform: true,
        label: {
          offsetY: 0,
          content: (item) => {
            this.loopType = item.json ? JSON.parse(item.json).type : "";
            if (item.type == "kfy") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/kfytr.png"),
                },
              });
              // group.addShape({
              //   type: 'text',
              //   attrs: {
              //     x: 6,
              //     y: 2,
              //     text: item.type,
              //     textAlign: 'center',
              //     textBaseline: 'top',
              //     fill: '#000'
              //   }
              // })
              return group;
            } else if (item.type == "ts") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/sxTXkccS/01.png",
                },
              });
              return group;
            } else if (item.type == "yds") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/ydstr.png"),
                },
              });
              return group;
            } else if (item.type == "yd") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/rwxTWjrJ/image.png",
                },
              });
              return group;
            } else if (item.type == "zx") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/zxtr.png"),
                },
              });
              return group;
            }
            // else if (this.loopType == 1) {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       x: 0,
            //       y: 14,
            //       width: 14,
            //       height: 14,
            //       img: require("../../../static/pic/blueLoop.png"),
            //     },
            //   });
            //   return group;
            // } else if (this.loopType == 2) {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       x: 0,
            //       y: 14,
            //       width: 14,
            //       height: 14,
            //       img: require("../../../static/pic/redLoop.png"),
            //     },
            //   });
            //   return group;
            // } else if (this.loopType == 3) {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       x: 0,
            //       y: 14,
            //       width: 14,
            //       height: 14,
            //       img: require("../../../static/pic/wave.png"),
            //     },
            //   });
            //   return group;
            // } else if (this.loopType == 4) {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       x: 0,
            //       y: 14,
            //       width: 14,
            //       height: 14,
            //       img: require("../../../static/pic/cancelWave.png"),
            //     },
            //   });
            //   return group;
            // } else if (this.loopType == 5) {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       // x: 4,
            //       // y: 18,
            //       x: 0,
            //       y: 14,
            //       width: 14,
            //       height: 14,
            //       img: "https://i.postimg.cc/4dwSSmsr/loopTS.png",
            //     },
            //   });
            //   // group.addShape({
            //   //   type: "text",
            //   //   attrs: {
            //   //     x: 13,
            //   //     y: 6,
            //   //     text: JSON.parse(item.json).carbs + "g",
            //   //     textAlign: "center",
            //   //     textBaseline: "top",
            //   //     fill: "#000",
            //   //   },
            //   // });
            //   return group;
            // } else if (this.loopType == 6) {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       // x: 2,
            //       // y: 18,
            //       x: 0,
            //       y: 14,
            //       width: 14,
            //       height: 14,
            //       img: "https://i.postimg.cc/NfZPShvf/loopYDS.png",
            //     },
            //   });
            //   // group.addShape({
            //   //   type: "text",
            //   //   attrs: {
            //   //     x: 11,
            //   //     y: 6,
            //   //     text: JSON.parse(item.json).bolus + "U",
            //   //     textAlign: "center",
            //   //     textBaseline: "top",
            //   //     fill: "#000",
            //   //   },
            //   // });
            //   return group;
            // } else if (this.loopType == 7) {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       x: 0,
            //       y: 14,
            //       width: 14,
            //       height: 14,
            //       img: require("../../../static/pic/base.png"),
            //     },
            //   });
            //   return group;
            // } else if (this.loopType == 8) {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       x: 0,
            //       y: 14,
            //       width: 14,
            //       height: 14,
            //       img: require("../../../static/pic/cancel.png"),
            //     },
            //   });
            //   return group;
            // }
            else if (item.eventType == "Correction Bolus") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 2,
                  y: 15,
                  width: 14,
                  height: 14,
                  img: "https://i.postimg.cc/NfZPShvf/loopYDS.png",
                },
              });
              return group;
            } else if (item.eventType == "Carb Correction") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 12,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/tstr.png"),
                },
              });
              return group;
            } else if (item.eventType == "Temporary Override") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/wavens.png"),
                },
              });
              return group;
            } else if (item.eventType == "Suspend Pump") {
              const group = new G.Group({});
              group.addShape({
                type: "image",
                attrs: {
                  x: 0,
                  y: 10,
                  width: 14,
                  height: 14,
                  img: require("../../../static/pic/pump.png"),
                },
              });
              return group;
            }
            // else {
            //   const group = new G.Group({});
            //   group.addShape({
            //     type: "image",
            //     attrs: {
            //       x: 0,
            //       y: 12,
            //       width: 14,
            //       height: 14,
            //       img: require("../../../static/pic/other.png"),
            //     },
            //   });
            //   return group;
            // }
          },
          labelLine: true,
        },
        tooltip: {
          showTitle: false,
          showCrosshairs: false,
          position: "top",
          customContent: (title, data) => {
            let loopType = title
              ? data[0].data.json
                ? JSON.parse(data[0].data.json).type
                : ""
              : "";
            let type = "";
            let eventType = title ? data[0].data.eventType : "";
            let res = title ? data[0].data : "";
            let parse = title
              ? data[0].data.json
                ? JSON.parse(data[0].data.json)
                : ""
              : "";
            if (title && data[0].data.type === "zx") {
              type = "指尖血";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 场景：${res.eventValue}</div>
                      <div style="margin-bottom:4px;">指血指数：${res.way}mmol/L</div>
                    </div>`;
            } else if (title && data[0].data.type === "kfy") {
              type = "口服药";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 用药时段：${res.scene}</div>
                      <div style="margin-bottom:4px;"> 药名：${res.way}</div>
                      <div style="margin-bottom:4px;"> 用量：${res.eventValue}</div>
                    </div>`;
            } else if (title && data[0].data.type === "ts") {
              type = "碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> ${res.eventValue}</div>
                    </div>`;
            } else if (title && data[0].data.type === "yds") {
              type = "胰岛素";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 注射时段：${res.scene}</div>
                      <div style="margin-bottom:4px;"> 胰岛素名称：${res.eventValue}</div>
                      <div style="margin-bottom:4px;"> 注射剂量：${res.way}U</div>
                    </div>`;
            } else if (title && data[0].data.type === "yd") {
              type = "运动";
              return `<div style="padding:6px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 运动时常：${res.scene}h</div>
                      <div style="margin-bottom:4px;"> 消耗：${res.eventValue}kcal</div>
                      <div style="margin-bottom:4px;"> 备注：${res.way}</div>
                    </div>`;
            } else if (title && loopType == 1) {
              type = "关闭闭环";
            } else if (title && loopType == 2) {
              type = "开启闭环";
            } else if (title && loopType == 3) {
              type = "覆盖方波";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 持续时间：${parse.minutes}</div>
                      <div style="margin-bottom:4px;"> 原因：${parse.fugainame}</div>
                    </div>`;
            } else if (title && loopType == 4) {
              type = "取消方波";
            } else if (title && loopType == 5) {
              type = "碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 总量：${parse.carbs}g</div>
                      <div style="margin-bottom:4px;"> 吸收时间：${parse.carbsHouers}h</div>
                    </div>`;
            } else if (title && loopType == 6) {
              type = "大剂量";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 剂量：${parse.bolus}U</div>
                    </div>`;
            } else if (title && loopType == 7) {
              type = "临时基础率";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 临时基础率：${parse.perHour}U/h</div>
                      <div style="margin-bottom:4px;"> 持续时间：${parse.baseHouers}h</div>
                    </div>`;
            } else if (title && loopType == 8) {
              type = "取消临时";
            } else if (title && eventType == "Correction Bolus") {
              type = "胰岛素";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 剂量：${
                        res.insulin
                      }U</div>
                      <div style="margin-bottom:4px;"> 持续时间：${(
                        res.duration * 1
                      ).toFixed(2)}</div>
                      <div style="margin-bottom:4px;"> 名称：${
                        res.insulinType
                      }</div>
                    </div>`;
            } else if (title && eventType == "Carb Correction") {
              type = "碳水";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 克数：${
                        res.carbs ? res.carbs + "g" : "无"
                      }</div>
                      <div style="margin-bottom:4px;"> 类型：${
                        res.foodType ? res.foodType : "无"
                      }</div>
                    </div>`;
            } else if (title && eventType == "Temporary Override") {
              type = "覆盖方波";
              return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div style="margin-bottom:4px;"> ${title}</div>
                      <div style="margin-bottom:4px;"> 需求比例：${
                        res.insulinNeedsScaleFactor
                      }</div>
                      <div style="margin-bottom:4px;"> 持续时间：${(
                        res.duration * 1
                      ).toFixed(2)}分钟</div>
                      <div style="margin-bottom:4px;"> 原因：${res.reason}</div>
                    </div>`;
            } else if (title && eventType == "Suspend Pump") {
              type = "泵";
            } else {
              type = "其他";
            }
            return `<div style="padding:4px;">
                      <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                      <div> ${title}</div>
                    </div>`;
          },
        },
        xAxis: {
          type: "time",
          visible: false,
          max: this.maxTime,
          min: this.minTime,
          tickInterval: this.tickInterval,
          mask: "HH:mm", // 显示格式为小时:分钟
          tickMethod: (scale) => {
            const { min, max, tickCount } = scale;
            // const avg = (max - min) / tickCount;
            const ticks = [];
            for (let i = min; i <= max; i += this.tickInterval) {
              ticks.push(i);
            }
            return ticks;
          },
        },
        yAxis: {
          visible: false,
          grid: {
            visible: false,
          },
        },
        pointStyle: {
          fill: "transparent",
          stroke: "#fff",
        },
      });
      this.scatterPlot2.render();
    },

    getTempChart() {
      const data = this.tempBasal;
      this.tempPlot = new Line("temp", {
        appendPadding: [0, 15, 0, 11],
        data,
        xField: "create_time",
        yField: "rate",
        stepType: "hv",
        connectNulls: false, // 不连续的数据是否连接上
        tooltip: false,
        xAxis: {
          type: "time",
          visible: false, // x轴隐藏
          max: this.maxTime,
          min: this.minTime,
          tickInterval: this.tickInterval,
          mask: "HH:mm", // 显示格式为小时:分钟
          tickMethod: (scale) => {
            const { min, max, tickCount } = scale;
            const ticks = [];
            for (let i = min; i <= max; i += this.tickInterval) {
              ticks.push(i);
            }
            return ticks;
          },
        },
        yAxis: {
          visible: false, // y轴隐藏
          max: this.maxRate,
          min: 0,
          grid: {
            visible: false, // 栅格隐藏
          },
        },
        lineStyle: {
          lineWidth: 1.3,
        },
        // area: {
        //   style: {
        //     fillOpacity: 0.55,
        //   },
        // },
        annotations: [
          // {
          //   type: "region",
          //   start: ["min", 0],
          //   end: ["max", 2],
          //   style: {
          //     stroke: "#edf0f2",
          //     lineDash: [2, 2],
          //     color: "#000",
          //   },
          // },
          // {
          //   type: "line",
          //   start: ["0%", "50%"],
          //   end: ["100%", "50%"],
          //   style: {
          //     stroke: "red",
          //     lineDash: [2, 2],
          //   },
          // },
        ],
      });
      this.tempPlot.render();
    },

    // 切换时间
    getDateArray(time) {
      this.chartData = [];
      const routePath = this.$route.path;
      const appuser = localStorage.getItem("appuser");
      const appuserId = JSON.parse(appuser).id;
      const params = {
        id: routePath == "/followView" ? this.userId : appuserId,
        end: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        start: this.$moment()
          .subtract(time, "hours")
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      if (time == 3) {
        this.minTime = this.minTime3;
        this.tickInterval = 30 * 60 * 1000;
        this.startEventTime = this.$moment()
          .subtract(2, "hours")
          .format("YYYY-MM-DD HH:00:00");
      } else if (time == 6) {
        this.minTime = this.minTime6;
        this.tickInterval = 60 * 60 * 1000;
        this.startEventTime = this.$moment()
          .subtract(5, "hours")
          .format("YYYY-MM-DD HH:00:00");
      } else if (time == 12) {
        this.minTime = this.minTime12;
        this.tickInterval = 2 * 60 * 60 * 1000;
        this.startEventTime = this.$moment()
          .subtract(11, "hours")
          .format("YYYY-MM-DD HH:00:00");
      } else if (time == 24) {
        this.minTime = this.minTime24;
        this.tickInterval = 4 * 60 * 60 * 1000;
        this.startEventTime = this.$moment()
          .subtract(23, "hours")
          .format("YYYY-MM-DD HH:00:00");
      }
      this.getTreat();
      this.getAllEvent();
      getSugerbyId(params).then((res) => {
        if (res.data.data && res.data.data.length) {
          res.data.data.forEach((item) => {
            this.chartData.push({
              // create_time: item.create_time.substring(11, 16),
              create_time: item.t,
              v: item.v,
              tooltip_time: item.create_time.substring(5, 16),
            });
          });
        }
      });
    },
    // 退出全屏
    outFull() {
      this.isFullScreen = false;
      this.$emit("getIsFull", false);
      document.querySelector("#container").style.height = "18rem";
      document.querySelector("#temp").style.bottom = "55px";
      document.querySelector("#temp").style.height = "55px";
    },
    // 进入全屏
    inFull() {
      this.isFullScreen = true;
      this.$emit("getIsFull", true);
      const nowWidth = window.screen.width;
      document.querySelector("#container").style.height = nowWidth - 100 + "px";
      // document.querySelector('#container').classList.add('shuipingChart')
      document.querySelector("#temp").style.bottom = "70px";
      document.querySelector("#temp").style.height = "76px";
    },
  },
  computed: {
    maxTime() {
      const nextTime = this.$moment()
        .add(1 + this.timerNum - this.timerNum, "hours")
        .format("YYYY-MM-DD HH:00:00");
      return new Date(nextTime).getTime();
    },
    minTime3() {
      const min = this.$moment()
        .subtract(2 + this.timerNum - this.timerNum, "hours")
        .format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
    minTime6() {
      const min = this.$moment()
        .subtract(5 + this.timerNum - this.timerNum, "hours")
        .format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
    minTime12() {
      const min = this.$moment()
        .subtract(11 + this.timerNum - this.timerNum, "hours")
        .format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
    minTime24() {
      const min = this.$moment()
        .subtract(23 + this.timerNum - this.timerNum, "hours")
        .format("YYYY-MM-DD HH:00:00");
      return new Date(min).getTime();
    },
  },
  watch: {
    chartData: {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.$nextTick(() => {
            if (this.scatterPlot) {
              this.scatterPlot.destroy();
              this.getChart();
            }
          });
        }
      },
    },

    // 切换时间后会渲染chart2，要先清除
    time: {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.$nextTick(() => {
            this.scatterPlot2.destroy();
          });
        }
      },
    },

    // 每五分钟自动渲染一次chart2，要先清除
    timerNum: {
      handler: function (newVal, oldVal) {
        this.$nextTick(() => {
          this.scatterPlot2.destroy();
        });
      },
    },

    tempBasal: {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.$nextTick(() => {
            if (this.tempPlot) {
              this.tempPlot.destroy();
              this.getTempChart();
            }
          });
        }
      },
    },
  },
};
</script>
<style scoped>
* {
  list-style: none;
}
.common-box {
  width: 96%;
  margin: 0.6rem;
  border-radius: 1.2rem;
  background: rgb(255, 255, 255);
  box-shadow: 2px 2px 2px 0px rgba(6, 14, 33, 0.16);
  position: relative;
}
.common-title {
  height: 3.4rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: start;
  line-height: 3.4rem;
  /* border-bottom: 1px solid #ccc; */
  padding-left: 1rem;
  background: aliceblue;
}
.van-radio-group--horizontal {
  justify-content: center;
}
.chartCan {
  width: 100%;
  /* background: #d3eeff; */
  position: absolute;
  /* border-top: 2px dotted #c1b8b8;
  border-bottom: 2px dotted #c1b8b8; */
  height: 4.8rem;
  bottom: 9rem;
}
.bottom-num {
  position: absolute;
  right: 5px;
  bottom: -16px;
  color: red;
}
.top-num {
  position: absolute;
  right: 5px;
  top: 0px;
  color: orange;
}
.unit {
  position: absolute;
  left: 5px;
  margin-top: 5px;
}
.van-radio__label {
  color: #7accff;
}
.content {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
}
.handleShow {
  position: absolute;
  right: 1rem;
  top: 0.4rem;
  width: 30px;
  height: 30px;
}
.handleShow img {
  width: 25px;
  height: 25px;
}
.shuiping {
  width: 100vh;
  height: 100vw;
  border-radius: 0;
  /* box-shadow: 0; */
  position: absolute;
  top: 49%;
  left: 48%;
  transform: translate(-50%, -50%) rotate(90deg);
  z-index: 9999;
  /* 阻止屏幕拖动 */
  overflow: hidden;
  touch-action: none;
}
.imgDom > li {
  position: absolute;
  top: 4rem;
}
.imgDom > li img {
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.imgDom > li > div {
  display: none;
  position: absolute;
  left: -12px;
  top: 17px;
  width: 40px;
  height: 40px;
  background-color: black;
  color: #fff;
  border-radius: 5px;
}
/* 事件标记样式 */
.marker {
  position: absolute;
  top: 2.5rem;
  z-index: 99;
  width: 100%;
  height: 7rem;
}
/* 梯形图样式 */
#temp {
  position: absolute;
  width: 100%;
  height: 55px;
  bottom: 55px;
  z-index: 99;
}
</style>
