<template>
  <div id="EditDialog">
    <van-nav-bar v-if="registerType" title="个人信息" />
    <van-nav-bar
      v-else
      title="个人信息"
      left-arrow
      @click-left="goBackMineView"
    />
    <van-cell title="头像" is-link center>
      <template #default>
        <img
          @click="openUpload"
          :src="
            !userInfo.images || userInfo.images == '未设置'
              ? 'https://i.postimg.cc/2SrXwLXR/litangbao.png'
              : userInfo.images
          "
          style="width: 50px; height: 50px; border-radius: 5px"
          alt=""
        />
      </template>
    </van-cell>
    <van-cell
      title="昵称"
      is-link
      @click="nameShow = true"
      :value="userInfo.name ? userInfo.name : '无'"
    />
    <van-dialog
      v-model="nameShow"
      title="修改昵称"
      show-cancel-button
      @confirm="update()"
    >
      <van-field
        v-model="userInfo.name"
        input-align="center"
        placeholder="请输入昵称"
      />
    </van-dialog>
    <van-cell title="账号" :value="userInfo.iphone ? userInfo.iphone : '无'" />
    <!-- @click="phoneShow = true" -->
    <van-dialog v-model="phoneShow" title="修改账号" show-cancel-button>
      <van-field
        v-model="userInfo.iphone"
        input-align="center"
        placeholder="请输入账号"
      />
    </van-dialog>
    <van-cell
      is-link
      @click="passwordShow = true"
      title="密码"
      :value="userInfo.password ? userInfo.password : '无'"
    />
    <van-dialog
      v-model="passwordShow"
      title="修改密码"
      show-cancel-button
      @confirm="update()"
    >
      <van-field
        v-model="userInfo.password"
        input-align="center"
        placeholder="请输入密码"
      />
    </van-dialog>
    <van-cell
      title="城市"
      is-link
      @click="areaShow = true"
      :value="userInfo.city ? userInfo.city : '无'"
    />
    <van-popup v-model="areaShow" round position="bottom">
      <van-area
        title="选择城市"
        :area-list="areaList"
        @cancel="areaShow = false"
        @confirm="areaSave"
      />
    </van-popup>

    <div style="margin: 16px" v-if="registerType">
      <van-button round block type="info" native-type="submit" @click="goSugar"
        >提交</van-button
      >
    </div>

    <el-dialog
      title="上传头像"
      :show-close="false"
      :visible.sync="showUpdateFile"
      width="80%"
    >
      <el-upload
        v-loading="loading"
        class="avatar-uploader"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-change="fileChange"
        :auto-upload="false"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
      >
        <img
          v-if="headSculpture"
          :src="headSculpture"
          class="avatar"
          style="width: 12rem; height: 12rem"
        />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showUpdateFile = false">取 消</el-button>
        <el-button type="primary" @click="updateImageFile">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import options from "./data";
import { Toast } from "vant";
import camera from "../../../static/pic/camera.png";
import * as qiniu from "qiniu-js";
import { update, getQniuToken } from "../../../api/userServices";
export default {
  name: "EditDialog",
  props: ["registerType"],
  data() {
    return {
      areaShow: false,
      phoneShow: false,
      passwordShow: false,
      areaList: areaList,
      nameShow: false,
      options: options,
      dialogVisible: false,
      loading: false,
      headSculpture: "",
      showUpdateFile: false,
      filePath: "",
      camera: camera,
      fileList: [],
      userInfo: {},
      tableData: [
        {
          label: "昵称",
          value: "222",
          source: "name",
        },
        {
          label: "性别",
          value: "222",
          source: "sex",
        },
        {
          label: "手机号",
          value: "222",
          source: "iphone",
        },
        {
          label: "城市",
          value: "222",
          source: "city",
        },
      ],
    };
  },
  mounted() {
    this.setUserInfo();
  },
  methods: {
    goSugar() {
      this.$router.push({
        path: "/BloodSugar",
        query: { index: 0 },
      });
      Toast("登录成功！");
    },
    // 保存城市选择
    areaSave(e) {
      this.userInfo.city = e[0].name + e[1].name + e[2].name;
      this.areaShow = false;
      this.update();
    },
    goBackMineView() {
      this.$emit("goBackMineView");
    },
    updateImageFile() {
      this.loading = true;
      this.updateHeadSculpture();
      setTimeout(() => {
        this.showUpdateFile = false;
        this.loading = false;
      }, 2000);
    },
    openUpload() {
      this.showUpdateFile = true;
    },
    fileChange(file) {
      this.fileList.push(file.raw);
      this.file = file.raw;
      this.fileList = [];
      this.loading = true;
      const userId = JSON.parse(localStorage.getItem("appuser")).id;
      const suffix = this.file.name.split(".")[1]; // 后缀名
      const timestamp = Date.parse(new Date());
      const key = userId + timestamp + "." + suffix; // key为上传后文件名 必填
      const config = { useCdnDomain: true, region: qiniu.region.z0 };
      const putExtra = { fname: this.file, params: {}, mimeType: null };
      const observe = {
        next(res) {
          console.log(res);
        },
        error(err) {
          console.log(err);
        },
        /* 完成后的操作 */
        complete(res) {
          // 上传成功以后会返回key 和 hash  key就是文件名了！
          console.log(res);
          // let fileUrl =baseUrl + '/' + res.key
        },
      };
      // 开始上传  token 需要找后端获取(单独的方法)
      getQniuToken().then((res) => {
        const token = res.data.data;
        const headers = qiniu.getHeadersForMkFile(token);
        // file 是获取到的文件对象
        // key 是文件名字，传null将使用hash值来当作文件名
        const observable = qiniu.upload(
          this.file,
          key,
          token,
          headers,
          putExtra,
          config
        );
        this.subscription = observable.subscribe(observe);
        setTimeout(() => {
          this.headSculpture = "https://oss.cgm.go-sugar.cn/" + key;
          this.loading = false;
        }, 2000);
      });
    },
    editUserInfo(item) {
      this.dialogVisible = true;
    },
    setUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem("appuser"));

      console.log(this.userInfo, "this.userInfo");

      this.headSculpture =
        !this.userInfo.images || this.userInfo.images == "未设置"
          ? "https://i.postimg.cc/2SrXwLXR/litangbao.png"
          : this.userInfo.images;
      for (let index = 0; index < this.tableData.length; index++) {
        this.tableData[index].value = this.userInfo[
          this.tableData[index].source
        ]
          ? this.userInfo[this.tableData[index].source]
          : "未设置";
      }
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!')
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    canceleEdit() {
      this.setUserInfo();
      this.dialogVisible = false;
    },
    updateChange() {
      this.update();
      this.dialogVisible = false;
    },
    // 头像修改完立即上传
    updateHeadSculpture() {
      const user = JSON.parse(localStorage.getItem("appuser"));
      user.images = this.headSculpture;
      update(user).then((res) => {
        if (res.data.code === "2003") {
          localStorage.setItem("appuser", JSON.stringify(user));
          this.setUserInfo();
        } else {
          Toast(res.data.msg);
        }
      });
    },
    update() {
      const user = JSON.parse(localStorage.getItem("appuser"));
      user.id = user.id ? user.id : "未设置";
      user.name = this.userInfo.name ? this.userInfo.name : "未设置";
      user.password = this.userInfo.password
        ? this.userInfo.password
        : "未设置";
      user.createdate = user.createdate ? user.createdate : "未设置";
      user.images = this.headSculpture ? this.headSculpture : "未设置";
      user.city = this.userInfo.city ? this.userInfo.city : "未设置";
      user.iphone = this.userInfo.iphone ? this.userInfo.iphone : "未设置";
      user.cgm = user.cgm ? user.cgm : "未设置";
      update(user).then((res) => {
        if (res.data.code === "2003") {
          localStorage.setItem("appuser", JSON.stringify(user));
          this.setUserInfo();
        } else {
          Toast(res.data.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
#EditDialog {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  background: #ededed;
}
.van-cell {
  margin-bottom: 2px;
}
.el-dialog {
  border-radius: 1rem !important;
}
.el-input__inner {
  color: #88d0fc !important;
  border: none !important;
}
::v-deep .el-dialog__footer {
  text-align: center !important;
}
</style>
