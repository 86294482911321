<template>
  <div id="tsView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div>饮食打卡</div>
    </div>
    <div class="mainBody">
      <van-cell title="开始用餐时间" is-link :value="selectTime" @click="openSelectTime" />
      <van-cell title="用餐类型" is-link :value="EatType" @click="openEatType" />
      <van-field label="食物名称" style="padding-right: 3rem;" v-model="foodName" input-align="right" placeholder="例如米饭、牛肉、蔬菜等" />
    </div>
    <el-col :offset="2" :span="20">
      <el-button @click="addts" style="width: 100%;color:#fff; background: #00dac4; border-radius: 1rem;margin-bottom: 6rem;height: 3rem;">
        完成
      </el-button></el-col>
    <van-popup v-model="showTimeSelect" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="currentDate" type="datetime" title="选择完整时间" :min-date="minDate" :max-date="maxDate" @confirm="saveTime" :formatter="formatter" />
    </van-popup>
    <van-popup v-model="showEatType" round position="bottom" :style="{ height: '50%' }">
      <van-picker style="line-height: 2rem;" show-toolbar :columns="columns" @confirm="onConfirm" @cancel="closeEatType" />
    </van-popup>
  </div>
</template>
<script>
import { addEvent } from '@/api/userServices'
export default {
  name: 'tsView',
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      imageUrl: '',
      showEatType: false,
      columns: ['餐前', '餐时', '餐后'],
      EatType: '餐前',
      foodName: '',
      TimeRange: '请选择',
      eatType: '请选择',
      showTimeSelect: false,
      selectTime: this.getFormatDate(new Date()),
      endTime: this.getFormatDate(new Date()),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date()
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.push('/login')
    },
    openEatType() {
      this.showEatType = true
    },
    onConfirm(value, index) {
      this.EatType = value
      this.showEatType = false
    },
    closeEatType() {
      this.showEatType = false
    },
    openSelectTime() {
      this.showTimeSelect = true
    },
    saveTime(value) {
      this.selectTime = this.getFormatDate(value)
      this.showTimeSelect = false
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    getFormatDate(time) {
      const date = new Date(time)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      let hour = date.getHours()
      let minutes = date.getMinutes()
      let seconds = date.getSeconds()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      hour = hour < 10 ? '0' + hour : hour
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds
      const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
      return currentDate
    },
    addts() {
      const params = {
        type: 'ts',
        createTime: this.selectTime,
        scene: '--',
        way: this.EatType,
        slopeOrdinal: localStorage.getItem('slopeOrdinal') ? localStorage.getItem('slopeOrdinal') : '3',
        value: localStorage.getItem('bloodSuger') ? localStorage.getItem('bloodSuger') : '5',
        eventValue: this.foodName
      }
      addEvent(params).then(res => {
        if (res.data.code === '200') {
          this.$message.success('添加成功!')
          this.$router.push('/bloodSugar')
        } else {
          this.$message.error('添加失败，请输入完整信息！')
        }
      })
    }
  }
}
</script>
<style scoped>
#tsView {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #fff;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 100;
}

.topHeader {
  height: 4rem;
  line-height: 4rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
}

.mainBody {
  text-align: left;
}
.el-icon-arrow-left {
  position: absolute;
  top: 0;
  left: 24px;
  line-height: 4rem;
  font-size: 2rem;
}
</style>
